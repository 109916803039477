var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm6: "",
                    lg2: "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("h3", [
                    _vm._v(" " + _vm._s(_vm.$t("pages.wards.all_wards")) + " "),
                  ]),
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-flex",
                {
                  attrs: { xs3: "", "align-self-center": "", "text-right": "" },
                },
                [
                  _c("AppNavButton", {
                    attrs: {
                      route: { name: "ward.add" },
                      "custom-class": "pa-5",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("v-icon", { attrs: { size: "14px" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("pages.wards.add_new_ward")) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": _vm.$t("messages.info.no_items", {
                        type: _vm.$t("pages.wards.title"),
                      }),
                    },
                    on: {
                      paginate: (val) => (_vm.pagination.page = val),
                      delete: _vm.toggleDelete,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleDelete, submit: _vm.deleteDepartment },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }